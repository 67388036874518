export const SITE_URL = "https://zero.parti.mx";
export const SITE_NAME = "빠띠 믹스";
export const HOME_GROUP_ID = "1";
/* 사용자 가입시 이 그룹들에 기본으로 가입됨 */
export const DEFAULT_GROUP_IDS = [HOME_GROUP_ID, "mx_trial"];
export const EMAIL = "mix@parti.coop";

/* 이용약관 */
export const POLICY_TERMS = "https://parti.mx/terms";

/* 개인정보 처리방침 */
export const POLICY_PRIVACY = "https://parti.mx/privacy";

/* 운영원칙 */
export const POLICY_RULES = "https://parti.mx/rules";

/* 마케팅 이용 */
export const POLICY_MARKETING = "https://parti.mx/privacy#terms_marketing";

/* 공유 설정 */
export const KAKAO_JS_KEY = "21cd8a6129205163194430f64b6ce4ef";
