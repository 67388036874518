import React from "react";
import { Helmet } from "react-helmet";
import { SITE_NAME } from "./config/Site";
import Routes from "./config/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./config/ThemeCustom";
import AnalyticsComponent from "./components/AnalyticsComponent";

export default function App() {
  window.location.replace('https://parti.mx');
  return null;

/*  return (
    <ThemeProvider theme={theme}>
      <Helmet defaultTitle={SITE_NAME} titleTemplate={"%s - " + SITE_NAME} />
      <Router>
        <AnalyticsComponent />
        <Routes />
      </Router>
    </ThemeProvider>
  );*/
}
